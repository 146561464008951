import React, { useEffect, useState/*, useRef*/ } from 'react';
import TimerInput from './TimerInput';

const Timer = ({ seconds: secondsIn }) => {
  const [isActive, setIsActive] = useState(true);
  const [seconds, setSeconds] = useState(secondsIn);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    let interval = null;
    const calculatedSeconds = seconds - 1;

    if (isActive) {
      interval = setInterval(() => {
        if (isActive && (calculatedSeconds === -1)) {
          setIsActive(false);
          return;
        }
        const minIn = Math.floor(calculatedSeconds / 60);
        const secIn = calculatedSeconds - (minIn * 60);

        setMin(minIn);
        setSec(secIn);
        // console.warn('time1 ', minIn, min, secIn, sec);

        setSeconds(calculatedSeconds);
      }, 1000);
    } else if (!isActive && calculatedSeconds !== 0) {
      clearInterval(interval);
    } else if (isActive && calculatedSeconds === 0) {
      setIsActive(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, seconds]);

  const handleChange = (event) => {
    const time = event.target.value;
    const secondsRemaining = time * 60;

    setSeconds(secondsRemaining);
    const minIn = Math.floor(secondsRemaining / 60);
    const secIn = secondsRemaining - (minIn * 60);
    setMin(minIn);
    setSec(secIn);
    // console.warn('time ', minIn, secIn);
    setIsActive(true);
  };

  return(
    <div>
      <TimerInput handleChange={handleChange} />
      <h1 style={{
        fontSize: 100,
        marginLeft:100
      }}>{min}:{sec}</h1>
    </div>
  )
};

export default Timer;
