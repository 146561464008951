import React/*, { useEffect, useState }*/ from 'react';

const TimerInput = ({ value, handleChange }) => {
  return (
    <div>
      <h3>Input your desired time</h3>
      <input type="number" value={value} onChange={handleChange} required />
    </div>
  );
};

export default TimerInput;
